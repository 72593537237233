import Vue from 'vue'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

/* Alias Component */
Vue.component("VValidate", () => import("@components/VValidate.vue"))
Vue.component("Pagination", () => import("laravel-vue-pagination"))
Vue.component("Loader", () => import("@components/Loader.vue"))
Vue.component("ImageFilter", () => import("@components/ImageFilter.vue"))
Vue.component("ImagePreview", () => import("@components/ImagePreview.vue"))
Vue.component("Uploader", () => import("@components/Uploader.vue"))
Vue.component("InputRadio", () => import("@components/InputRadio.vue"))
Vue.component("LabelStatus", () => import("@components/LabelStatus.vue"))
Vue.component("vue-json-pretty", VueJsonPretty)