export default [
  /**
   * NO AUTH
   */
  {
    path: '/login',
    name: 'Login',
    component: () => import('@view/Login.vue'),
    meta: {
      redirectIfLoggedIn: true,
      menu:'Login'
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@view/ForgotPassword.vue'),
  },
  {
    path: '/403',
    name: 'Page403',
    component: () => import('@view/Page403.vue'),
    meta: {
      redirectIfLoggedIn: true,
      menu: "Page403",
    },
  }, 
  {
    path: '/redirect',
    component: () => import('@view/Login.vue'),
    name: 'RedirectLogin',
    meta: {
      redirectIfLoggedIn: true,
      menu: 'Login'
    },
  },

  /**
   * WITH AUTH
   */
  {
    path: "",
    component: () => import('@view/include/Layout.vue'),
    redirect: { name: "Dashboard" },
    children: [
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('@view/Dashboard.vue'),
        meta: {
          menu: "Dashboard",
          pageTitle:'Dashboard',
        },
      },
      {
        path: '/account-setting',
        name: "AccountSetting",
        component: () => import('@view/Account.vue'),
        meta: {
          menu: "AccountSetting",
          allowed:true,
          pageTitle: "Account Setting"
        }
      },
      {
        path: '/notification',
        name: "Notification",
        component: () => import("@view/Notification.vue"),
        meta: {
          menu: "Notification",
          allowed:true,
          pageTitle: "Notifikasi"
        }
      },
      {
        path: '/mail/:tab/:pageSlug?',
        name: "Mail",
        component: () => import("@view/Mail.vue"),
        meta: {
          menu: "Mail",
          allowed:true,
          pageTitle: "Pesan"
        }
      },
      {
        path: '/register-risiko/:pageSlug?',
        name: 'RegisterResiko',
        component: () => import('@view/RegisterResiko.vue'),
        meta: {
          menu: "RegisterResiko",
          pageTitle:'Register Risiko',
        },
      },
      {
        path: '/penaksiran/:pageSlug?/:pageId?',
        name: 'Penaksiran',
        component: () => import('@view/Penaksiran.vue'),
        meta: {
          menu: "Penaksiran",
          pageTitle: 'Penaksiran',
        },
      },
      {
        path: '/mitigasi/:pageSlug?/:pageId?',
        name: 'PenaksiranMitigasi',
        component: () => import('@view/PenaksiranMitigasi.vue'),
        meta: {
          menu: "PenaksiranMitigasi",
          pageTitle: 'Mitigasi',
        },
      },
      {
        path: '/risiko-terbaru',
        name: 'RisikoTerbaru',
        component: () => import('@view/RisikoTerbaru.vue'),
        meta: {
          menu: "RisikoTerbaru",
          pageTitle: "Risiko Terbaru",
          order: 'newest'
        }
      },
      {
        path: '/laporan/:periode?/:pageSlug?',
        name: 'Laporan',
        component: () => import('@view/Laporan.vue'),
        meta: {
          menu: "Laporan",
          pageTitle: "Laporan"
        }
      },
      {
        path: '/profile-risiko',
        name: 'ProfileRisiko',
        component: () => import('@view/ProfileRisiko.vue'),
        meta: {
          menu: "ProfileRisiko",
          pageTitle: "Profile Risiko"
        }
        
      },
      {
        path: '/loss-event-database/:pageSlug?',
        name: "LostEventDatabase",
        component: () => import('@view/LostEventDatabase'),
        meta: {
          menu: "LostEventDatabase",
          pageTitle: "Loss Event Database"
        }
      },
      {
        path: '/delegasi/:pageSlug?',
        name: "Delegasi",
        component: () => import('@view/Delegasi'),
        meta: {
          menu: "Delegasi",
          pageTitle: "Delegasi"
        }
      },
      {
        path: '/master/perspektif/:pageSlug?',
        name: 'MrPerspektif',
        component: () => import('@view/MrPerspektif.vue'),
        meta: {
          menu: "MrPerspektif",
          pageTitle: 'Perspektif',
        },
      },
      {
        path: '/master/kantor/:pageSlug?',
        name: 'MrKantor',
        component: () => import('@view/MrKantor.vue'),
        meta: {
          menu: "MrKantor",
          pageTitle: 'Kantor',
        },
      },
      {
        path: '/master/kategori/:pageSlug?',
        name: 'MrCatResiko',
        component: () => import('@view/MrCatResiko.vue'),
        meta: {
          menu: "MrCatResiko",
          pageTitle: 'Kategori',
        },
      },
      {
        path: '/master/bidang/:pageSlug?',
        name: 'MrBidang',
        component: () => import('@view/MrBidang.vue'),
        meta: {
          menu: "MrBidang",
          pageTitle: 'Bidang',
        },
      },
      {
        path: '/master/unit-kerja/:pageSlug?',
        name: "MrUnitKerja",
        component: () => import('@view/MrUnitKerja.vue'),
        meta: {
          menu: "MrUnitKerja",
          pageTitle: "Unit Kerja"
        }
      },
      {
        path: '/master/tanda-tangan/:pageSlug?',
        name: "Tandatangan",
        component: () => import('@view/MrTandaTangan.vue'),
        meta: {
          menu: "Tandatangan",
          pageTitle: "Tanda Tangan"
        }
      },
      {
        path: '/user-management/user-list/:pageSlug?',
        name: 'Users',
        component: () => import('@view/Users.vue'),
        meta: {
          menu: "Users",
          pageTitle: 'Daftar User',
        },
      },
      {
        path: '/user-management/user-roles/:pageSlug?',
        name: 'UserLevel',
        component: () => import('@view/UserLevel.vue'),
        meta: {
          menu: "UserLevel",
          pageTitle: 'User Akses',
        },
      },
      {
        path: '/audit-trail',
        name: 'AuditTrail',
        component: () => import('@view/AuditTrail.vue'),
        meta: {
          menu: "AuditTrail",
          pageTitle: 'Audit Trail',
        },
      },
      {
        path: '/error-log',
        name: 'ErrorLog',
        component: () => import('@view/ErrorLog.vue'),
        meta: {
          menu: "ErrorLog",
          pageTitle: 'Error Log',
        },
      },
      {
        path: '/master/pic-mitigasi/:pageSlug?',
        name: 'MrJabatan',
        component: () => import('@view/MrJabatan.vue'),
        meta: {
          menu: "MrJabatan",
          pageTitle: 'PIC',
        },
      },
      {
        path: '/manage-periode/:pageSlug?',
        name: 'ManagePeriode',
        component: () => import('@view/ManagePeriode.vue'),
        meta: {
          menu: "ManagePeriode",
          pageTitle: 'Atur Periode',
        },
      },
      {
        path: '/ganti-kantor/:pageSlug?',
        name: 'GantiKantor',
        component: () => import('@view/GantiKantor.vue'),
        meta: {
          menu: "GantiKantor",
          pageTitle: 'Ganti Kantor',
        },
      },
      {
        path: '/master/content/:pageSlug?',
        name: 'Content',
        component: () => import('@view/MrContent.vue'),
        meta: {
          menu: "Content",
          pageTitle: "Template Konten"
        }
      },
      {
        path: '/master/sasaran-rtu/:pageSlug?',
        name: 'SasaranRtu',
        component: () => import('@view/MrSasaranRtu.vue'),
        meta: {
          menu: "SasaranRtu",
          pageTitle: 'Sasaran RTU',
        },
      },
      {
        path: '/master/pustaka-risiko/:pageSlug?',
        name: 'MrPustakaRisiko',
        component: () => import('@view/MrPustakaRisiko.vue'),
        meta: {
          menu: "MrPustakaRisiko",
          pageTitle: 'Pustaka Risiko',
        },
      },
      {
        path: '/master/pustaka-risiko/:risikoSlug/sebab/:pageSlug?',
        name: 'MrRisikoSebab',
        component: () => import('@view/DetailRisikoSebab.vue'),
        meta: {
          menu: "MrRisikoSebab",
          allowed: true,
          pageTitle: 'Detail Risiko',
          risiko: 'sebab'
        },
      },
      {
        path: '/master/pustaka-risiko/:risikoSlug/dampak/:pageSlug?',
        name: 'MrRisikoDampak',
        component: () => import('@view/DetailRisikoDampak.vue'),
        meta: {
          menu: "MrRisikoDampak",
          allowed: true,
          pageTitle: 'Detail Risiko',
          risiko: 'dampak'
        },
      },

      {
        path: '/master/pustaka-risiko/:risikoSlug/kendali/:pageSlug?',
        name: 'MrRisikoKendali',
        component: () => import('@view/DetailRisikoPengendalian.vue'),
        meta: {
          menu: "MrRisikoKendali",
          allowed: true,
          pageTitle: 'Detail Risiko',
          risiko: 'kendali'
        },
      },
      {
        path: '/lost-event-database/:ledSlug/sebab/:pageSlug?',
        name: 'MrLedSebab',
        component: () => import('@view/DetailLedSebab.vue'),
        meta: {
          menu: "MrLedSebab",
          pageTitle: "LED Sebab",
          allowed: true,
          led: 'sebab'
        }
      },
      {
        path: '/lost-event-database/:ledSlug/dampak/:pageSlug?',
        name: 'MrLedDampak',
        component: () => import('@view/DetailLedDampak.vue'),
        meta: {
          menu: "MrLedDampak",
          pageTitle: "LED Dampak",
          allowed: true,
          led: 'dampak'
        }
      },
      {
        path: '/lost-event-database/:ledSlug/kendali/:pageSlug?',
        name: 'MrLedKendali',
        component: () => import('@view/DetailLedKendali.vue'),
        meta: {
          menu: "MrLedKendali",
          pageTitle: "LED Kendali",
          allowed: true,
          led: 'kendali'
        }
      },
      {
        path: '/cari-dokumen/bulan/:pageSlug?',
        name: 'CariDokumenBulan',
        component: () => import('@view/DokumenBulan.vue'),
        meta: {
          menu: "CariDokumenBulan",
          pageTitle: 'Dokumen Bulan',
        },
      },
      {
        path: '/cari-dokumen/triwulan/:pageSlug?',
        name: 'CariDokumenTriwulan',
        component: () => import('@view/DokumenTriwulan.vue'),
        meta: {
          menu: "CariDokumenTriwulan",
          pageTitle: 'Dokumen Triwulan',
        },
      }, 
      {
        path: '/risiko-korporat/:pageSlug?',
        name: 'RisikoKorporat',
        component: () => import('@view/RisikoKorporat.vue'),
        meta: {
          menu: "RisikoKorporat",
          pageTitle: 'Risiko Korporat',
        },
      }, 
      {
        path: '/detail-risiko-korporat/:pageSlug?',
        name: 'DetailRisikoKorporat',
        component: () => import('@view/DetailRisikoKorporat.vue'),
        meta: {
          menu: "DetailRisikoKorporat",
          pageTitle: 'Detail Risiko Korporat',
        },
      },
      {
        path: '/konfigurasi/:pageSlug?',
        name: 'Konfigurasi',
        component: () => import('@view/Konfigurasi.vue'),
        meta: {
          menu: "Konfigurasi",
          pageTitle: 'Konfigurasi',
        },
      },
    ],
  },
]
