import Vue from 'vue'
import VueRouter from 'vue-router'
import _ from 'lodash'

// Routes
import store from '@/store/index'
import bo_route from './bo_route'

Vue.use(VueRouter)

const state = store.state;

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    if (to.name == 'MrRisikoDampak' || to.name == 'MrRisikoSebab' || to.name == 'MrRisikoKendali') {
      return false
    }
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'Dashboard' } },
    ...bo_route,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

function moduleRole(user, role) {
  let tUser = _.clone(user)
  if (tUser.id_user_ghost && tUser.user_ghost) {
    tUser = tUser.user_ghost
  }

  if (tUser.levelCode == 'SADMIN') return true
  let data = (_.union((_.clone(tUser.role || [])), (_.clone(tUser.moduleRole || []))))

  if (["isAdministratorSystem", "isAuditor"].indexOf(role) > -1) {
    return data.indexOf(role) > -1 && data.indexOf('ViewOnly') <= 1
  } else {
    return data.indexOf(role) > -1
  }
}

router.beforeEach((to, _, next) => {

  const isLoggedIn = store.getters['authenticated']
  const user = state.user

  if (to.name == 'RedirectLogin' && (to.query||{}).code){
    
    store.commit('updateUserToken', (to.query || {}).code)

    return window.location = location.origin
  }else if (to.meta.menu) {
    store.dispatch('watchAuth').then(() => {
      const isLoggedInNew = store.getters['authenticated']
      if (!isLoggedInNew && isLoggedIn){

        return next({ name: 'Login' })

        /*if (state.mode == 'production') {
          return window.location = state.hokiUrl
        } else {
          return next({ name: 'Login' })
        }*/

      }
    })
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      // Redirect if logged in
      return next({ name: 'Dashboard' })
    } else if (!to.meta.redirectIfLoggedIn && !isLoggedIn) {
      // Redirect if not logged in
      
      return next({ name: 'Login' })
      
      /*if (state.mode == 'production') {

        return window.location = state.hokiUrl
      } else {
        return next({ name: 'Login' })
      }*/
      
    } else if (isLoggedIn && to.meta.menu && (user.menuRole || []).indexOf(to.meta.menu) <= -1) {
      // login AND not allowed menu > to first allowed menu
      if (store.getters['canManageAll'] || to.meta.allowed) {
        return next()

      } else {
        return next({ name: user.menuRole[0] })
      }
    }

    // module role
    if (
      !moduleRole(user, 'ViewOnly') ||
      (
        moduleRole(user, 'ViewOnly') &&
        (
          !(
            (
              !!to.params.pageSlug &&
              (
                to.params.pageSlug == 'add' ||
                to.params.pageSlug == 'add-pustaka' ||
                to.params.pageSlug == 'edit' ||
                !isNaN(to.params.pageSlug)
              )
            ) ||
            (
              !!to.params.pageId &&
              (
                to.params.pageId == 'add' ||
                to.params.pageId == 'add-pustaka' ||
                to.params.pageId == 'edit' 
              )
            )
          ) ||
          !to.params.pageSlug
        ) &&
        ['RegisterResiko'].indexOf(to.name)<=-1
      )
    ) {
      return next()
    } else {
      return next({ name: user.menuRole[0] })
    }

  }else{
    return next()
  }
})

export default router
