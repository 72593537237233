
<script>
import { mapState } from 'vuex'
import Config from './Config'
import Gen from '@libs/Gen.js'
import $ from 'jquery'
import _ from 'lodash'

export default {
  data(){
    return {
      filter: {},
      row: {},
      input:{},
      mrValidation: {},
      loadingOverlayTb:false,
      data: {data: false},
      duration:35000,
      pattern:{
        name:/^[a-zA-Z '.,]+$/,
        username:/^[a-zA-Z0-9_.]+$/,
        phone:/^(?=[1-9])[0-9]+$/,
      },
    }
  },
  computed:{
    ...mapState({
      production: state => state.production,
      apiUrl: state => state.apiUrl,
      baseUrl: state => state.baseUrl,
      hokiUrl: state => state.hokiUrl,
      webTitle: state => state.webTitle,
      userToken: state => state.userToken,
      user: state => state.user,
      mode: state => state.mode,
    }),
    loadingOverlay:{
      get(){ return this.$root.var.loadingOverlay },
      set(v){ return this.$root.var.loadingOverlay = v },
    },
    Config(){ return Config },

    isAdminLogin(){
      return Gen.getCookie('isAdminLogin')==1
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    },

    pageSlug(){ return this.$route.params.pageSlug },
    pageId(){ return this.$route.params.pageId },
    isAdd(){ return this.pageSlug == "add" },
    isList(){ return !this.pageSlug },
    isSorting(){return this.$route.query.sorting&&this.isList},
    isDelete(){return this.$route.query.delete&&this.isList},

    dataList(){return this.data.data },

    pageTitle(){return this.$route.meta?.pageTitle||""},
    pageTitleForm(){return (this.pageSlug == 'add' ? 'Add' : 'Edit')+' '+this.pageTitle},
    modulePage(){return this.$route.meta.menu},

    pageNo: {
      // getter
      get:function() {
        return this.filter.page||this.$route.query.page||1
      },
      // setter
      set:function(newValue) {
        this.$set(this.filter, "page", newValue);
        this.$router.push({
          name:this.modulePage,
          query:_.clone(this.filter)
        })
      }
    },

    perPage:{
      // getter
      get:function() {
        return this.filter.shown||this.$route.query.shown||Config.dataShown
      },
      // setter
      set:function(newValue) {
        this.$set(this.filter, "shown", newValue);
        this.$router.push({
          name:this.modulePage,
          query:_.clone(this.filter)
        })
      }
    },

    apiParams(){ return _.assign(_.clone(this.filter), _.clone(this.apiQuery||{})) },
    apiQuery(){return this.$route.query||{}},

    notFound(){
      if (this.data.data === false) return false
      if (this.data.data.length > 0) return false
      if (this.$route.query.search) {
        return "Search with keyword '" + this.$route.query.search + "' not found"
      }
      return this.pageTitle + " not found"
    },
    filterNotEmpty(){
      return $.grep(Object.keys(this.filter)||[], (attr)=>{
        return this.filter[attr] && attr!='page'
      }).length >= 1
    },
  },
  mounted() {
    this.filter = _.clone(this.$route?.query)
  },
  methods:{
    isType(data){
      return $.type(data)
    },
    apiGet(params = {}){
      const notLoading = !!params.notLoading

      if(this.pageSlug){
         if(!notLoading) this.loadingOverlay = true
         this.$set(this,'filter',{})
      }
      else if(!notLoading) this.loadingOverlayTb = true
      this.data.data = false
      this.input = {}
      this.row = {}
      Gen.apiRest(
        "/get/"+this.modulePage+
        (this.pageSlug?"/"+this.pageSlug:"")+
        (this.pageId?"/"+this.pageId:""), 
        {
          params: Object.assign({}, this.apiParams, params.query||{})
        }
      ).then(res=>{
        if(this.user.isActive !== 1 || this.user.levelIsActive !== 1){
          return this.doLogout()
        }

        this.loadingOverlay = false
        this.loadingOverlayTb = false
        _.forEach(res.data, (v,k)=>{
          this.$set(this, k, v)
        })

        // if(!this.isList){
        //   this.setFocusForm()
        // }
        
        if(params.callback) params.callback()
      }).catch(()=>{
        this.loadingOverlay = false
        this.loadingOverlayTb = false
      })
    },
    setFocusForm(){
      if(this.$refs.VForm.$el){
        let el = $(this.$refs.VForm.$el);
        if(el.find(':input:not([type=hidden])').length){
          el.find(':input:not([type=hidden]):first').focus()
        }else if(el.find('textarea').length){
          el.find('textarea:first').focus()
        }
      }
    },
    setTitle(){
      $("title").html(this.pageTitle+' - '+this.webTitle)
    },
    doFilter(){
      if(this.exportType && this.filter){
        let f = Object.values(this.filter).filter((attr)=>attr)
        if(f.length>=1){ 
          this.exportType = 'filtered'
        }else{
          this.exportType = 'all'
        }
      }
      this.$router.push({name:this.$route.name,query:Object.assign({},_.clone(this.filter), {page:1})}).catch(()=>{});
    },
    doReset(){
      this.filter={}
      this.$router.push({name:this.$route.name,query:{page:1}});
    },
    doChangeStatus(k,v, to=""){
      if(v[this.statusKey]==to) return
      let id = v[this.idKey]
      let changeStatus = v[this.statusKey] == "1" ? "2" : "1"
      global.Swal.fire({
        title: `Ingin mengubah status ke ${changeStatus=='1' ? 'Active' : 'Inactive'}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: changeStatus=="1"?'#30d64c':'#f7ab38',
        cancelButtonColor: '#3085d6',
        confirmButtonText:  `Ya, ${changeStatus=='1'? 'Active' : 'Inactive'}!`,
        cancelButtonText:  `Batal`,
        customClass: {
          // confirmButton: `btn btn-primary ${changeStatus =='1' ? 'btn-success' : 'btn-warning'}`,
          confirmButton: 'btn btn-info',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false
      }).then((result) => {
        if (result.value) {
          this.loadingOverlay=true

          Gen.apiRest(
            "/do/"+this.modulePage,
            {data:{type:'status',id:id,status:changeStatus}}, 
            "POST"
          )
            .then(()=>{
              this.loadingOverlay = false 
              v[this.statusKey] = changeStatus

              global.Swal.fire({
                title: `Status data berubah menjadi ${changeStatus=='1'? 'Active' : 'Inactive'}.`,
                icon: 'success',
              })
            })
            .catch((err)=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
              }
              this.doSetAlertForm(err);
            })
        }
      })
    },
    showErrorAfterSubmit(type, resp){
      if(resp?.response?.status==422) {
        if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
          var msg = ""
          $.each(Object.keys(resp.response.data),(i,v)=>{
            msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v]);
          })
          return global.Swal.fire("Blocked With Validation!", msg)
        }else{
          return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
        }
      }else if(resp?.response?.status==400){
        return global.Swal.fire({
          title: resp.response.data.message,
          icon: 'error',
          confirmButtonText: 'Ok'
        })
      }
    },
    doDelete(k,v, query = {}){
      let id = v[this.idKey]
      global.Swal.fire({
        title: 'Apakah Kamu Yakin?',
        text: "Data yang telah dihapus tidak dapat dipulihkan kembali!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Ya, hapus!',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loadingOverlay = true
          this.openForm = false;
          Gen.apiRest("/do/"+this.modulePage,{data:{type:'delete',id:id}}, "POST")
            .then(()=>{
              this.apiGet(query)
              global.Swal.fire({
                title: 'Data berhasil dihapus',
                icon: 'success',
              })
            })
            .catch((err)=>{ 
              this.loadingOverlay = false 
              if(err){
                  err.statusType = err.status;
                  err.status = "error";
                  err.message = err.response.data.message;
                  err.messageError = err.message
              }
              this.doSetAlertForm(err);
            })
        }
      })
    },
    reparse(js){
      return $.parseJSON(JSON.stringify(js))
    },
    round(num){
      return Math.round((parseFloat(num) + Number.EPSILON) * 100) / 100
    },
    doPaginate(page = 1){
      this.$set(this.filter, "page", page);
      this.$router.push({name:this.$route.name,query:_.clone(this.filter)})
    },
    doSubmit(action, input, callback=false, method="POST", IDFORM='VForm'){
      this.$refs[IDFORM].validate().then(success => {
        if (!success) { return; }
        
        if(this.loadingOverlay) return
        this.loadingOverlay = true 

        var data = $.parseJSON(JSON.stringify(input));
        var btnSubmit = $(".btn-loading");
        var prevHtml = btnSubmit.html();

        if($(".infoHTML").length){
          $(".infoHTML").removeClass('alert alert-danger alert-warning');
          $(".infoHTML").html('');
        }

        btnSubmit.html();
        Gen.apiRest(
          action, 
          {data: $.extend({params:this.params}, data)}, 
          method
        )
          .then((res)=>{
            this.loadingOverlay = false
            let resp = res.data

            btnSubmit.html(prevHtml);

            resp.statusType = 200;
            this.doSetAlertForm(resp);
            
            if(callback){
              callback('success', resp)
            }

            setTimeout(()=>{ this.message="" },15000);
          }).catch((err)=>{
            this.loadingOverlay = false
            btnSubmit.html(prevHtml);
            
            if(err){
                err.statusType = err.status;
                err.status = "error";
                err.message = err.response?.data?.message;
                err.messageError = err.message
            }

            this.doSetAlertForm(err);
            if(this.failReset)
                this.$nextTick(() => {
                    this.$refs.form.reset();
                    this.message=""
                });
                
            if(callback){
              callback('error', err)
            }
          })
      });
    },
    doSubmitCRUD(IDForm='VForm'){
      return this.doSubmit(
          "/do/"+this.modulePage, 
          _.assign({type:this.isAdd?'add':'update'}, {id: (this.pageId||this.pageSlug)}, _.clone(this.row)), 
          (type, resp)=>{
            if(type=='success'){
              this.$router.push({name:this.$route.name})
            }else{
              if(resp.response.status==422) {
                if((_.values(resp.response.data)[0][0]||"").indexOf('validation')>-1){
                  var msg = ""
                  $.each(Object.keys(resp.response.data),(i,v)=>{
                    msg = msg+" <br/>"+v+" : "+_.values(resp.response.data[v]);
                  })
                  return global.Swal.fire("Blocked With Validation!", msg)
                }else{
                  return global.Swal.fire("Sorry, There is something wrong!", _.values(resp.response.data)[0][0])
                }
              }else if(resp.response.status==400){
                return global.Swal.fire({
                  title: resp.response.data.message,
                  icon: 'error',
                  confirmButtonText: 'Ok'
                })
              }
            }
          },
          'POST', IDForm
      )
    },
    eType(e){return $.type(e)},
    doImport(){
      return this.doSubmit( "/do/"+this.modulePage, _.assign({type:'import-excel'}, {id:0}, _.clone(this.input)), (type, resp)=>{
          if(type=='success'){
            this.apiGet()
            this.$router.push({name:this.$route.name})
          }else{
            if(resp.response.status==400){
              return global.Swal.fire({
                title: resp.response.data.message,
                icon: 'error',
                confirmButtonText: 'Ok'
              })
            }
          }
        }
      )
    },
    doSetAlertForm(resp){
      if(!resp.message && !resp.title) return;
      var panel = $(".infoHTML");
      if(resp.alert=="normal"){
        if(["success","warning"].indexOf(resp.status)>-1){
          panel.attr("class","infoHTML alert alert-"+resp.status);
        }else{
          panel.attr("class","infoHTML alert alert-danger");
        }
        panel.html("<div class='alert-body'>"+resp.message+"</div>");

        setTimeout(()=>{
          panel.html("");
          panel.attr("class","infoHTML");
        }, this.duration)
      }else {
        if($.inArray(resp.status,["success","warning"])!=-1){
          return global.Swal.fire({
            title: resp?.title,
            text: resp.message,
            icon: resp.status,
            confirmButtonText: 'Ok'
          })
        }else{
          return global.Swal.fire({
            // title: resp?.title||'Sorry, There is something wrong!',
            title: resp.title,
            text: resp.message,
            icon: 'error',
            confirmButtonText: 'Ok'
          })
        }
      }
    },
    errorMessage(err){
      this.loadingOverlay = false
      let res = err.response
      if(res.status==422) return global.Swal.fire("Sorry, There is something wrong!", _.values(res.data)[0][0])
      global.Swal.fire("Sorry, There is something wrong!", res.data.message, "warning")
    },
    doLogout:function(swal = false){
      if(swal){
        global.Swal.fire({
          title: 'Anda yakin ingin Logout?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Ya, Logout',
          cancelButtonText: 'Batal',
        }).then(result => {
          if(result.value){
            this.$store.commit("logoutUserToken")
            Gen.setCookie('isAdminLogin', 0)
            if(!this.userToken){
              //window.location=this.apiUrl+'/api/do/logout'
              this.$router.push({name:"Login"})
            }
          }
        })
      }else{
        this.$store.commit("logoutUserToken")
        Gen.setCookie('isAdminLogin', 0)
        if(!this.userToken){
          this.$router.push({name:"Login"})
        }
      }
    },

    doLogOutSession:function(){
      this.$store.commit("logoutUserToken")
      Gen.setCookie('isAdminLogin', 0)
      if(!this.userToken){
        //window.location=this.apiUrl+'/api/do/logout/session'
        this.$router.push({name:"Login"})
      }
    },
    uploader(filename) {
      if(!filename){
        return this.apiUrl + '/img/noimage.png';
      }
      return this.apiUrl + '/uploader/' + filename;
    },
    configValue(data, value){
      let dt = _.filter((Config.master[data]||[]), (attr)=>{ return attr.value==value })
      return dt.length?dt[0].label:""
    },
    mrValue(data, value, field="label"){
      const dt = _.filter(data, (attr)=>{ return attr.value==value })
      return dt.length?dt[0][field]:""
    },
    moduleRole(role){
      let tUser = _.clone(this.user)
      if(tUser.id_user_ghost && tUser.user_ghost){
        tUser = tUser.user_ghost
      }

      if(tUser.levelCode=='SADMIN') return true 
      let data = (_.union( (_.clone(tUser.role||[])), (_.clone(tUser.moduleRole||[])) ))
  
      if(["isAdministratorSystem", "isAuditor"].indexOf(role)>-1){
        return data.indexOf(role)>-1 && data.indexOf('ViewOnly')<=1
      }else{
        return data.indexOf(role)>-1
      }
    },
    menuRole(role){
      let tUser = _.clone(this.user)
      if(tUser.id_user_ghost && tUser.user_ghost){
        tUser = tUser.user_ghost
      }

      if(tUser.levelCode=='SADMIN') return true 
      return (tUser.menuRole||[]).indexOf(role)>-1
    },

    toggleSidebar(){
			document.querySelector("body").classList.toggle('sidebar-mobile-main');
			if (document.querySelector(".sidebar-main").classList.contains('sidebar-fullscreen')) {
				document.querySelector(".sidebar-main").classList.remove('sidebar-fullscreen')
			}
		},
		fullscreenSidebar(){
			document.querySelector(".sidebar-main").classList.toggle('sidebar-fullscreen');
		}
  },
}
</script>