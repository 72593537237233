import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

Vue.config.productionTip = false

/* Global Config */
import './assets/extension'

/* 3rd Party Plugins */
import '@libs/alias'
import '@libs/bootstrap'
import '@libs/vue-select'
import '@libs/vee-validate'
import '@libs/v-tooltip'
import '@libs/moment'
import '@libs/sweetalert'
import '@libs/vue2-datepicker'
import nprogress from '@libs/nprogress'

/* register css */
require('@/assets/global.scss')
require('@/assets/css/style.min.css')
require('@/assets/css/custom_l9.css')
require('@/assets/css/responsive.css')

store.dispatch('watchAuth').then(() => {
  new Vue({
    nprogress,
    router,
    store,
    data() {
      return {
        var: { loadingOverlay: false },
        app: { uploader: {}, hoki_url:'' },
      }
    },
    render: h => h(App),
  }).$mount('#app')
})
