const Config = {
  app: {
    webaname: 'SIMMR',
    company: 'PT Jasa Raharja',
    copyright: () => {
      let date = new Date()
      if (date.getFullYear() > 2021) {
        return '2021-' + date.getFullYear()
      } else {
        return '2021'
      }
    }
  },
  wording:{
    no_data_listed:'Tidak ada data untuk ditampilkan',
    no_data_search:'Pencarian tidak ditemukan',
  },
  encrypt_key:"#@PWD!!",
  dataShownOptions: [
    { text: '10', value: 10 },
    { text: '20', value: 20 },
    { text: '30', value: 30 },
  ],
  dataShown: 10,
  master:{
    OptionInheren:[
      {value:1, label:1},
      {value:2, label:2},
      {value:3, label:3},
      {value:4, label:4},
      {value:5, label:5},
    ],
    OptionBulan:[
      {value:1,label:'Januari'},
      {value:2,label:'Februari'},
      {value:3,label:'Maret'},
      {value:4,label:'April'},
      {value:5,label:'Mei'},
      {value:6,label:'Juni'},
      {value:7,label:'Juli'},
      {value:8,label:'Agustus'},
      {value:9,label:'September'},
      {value:10,label:'Oktober'},
      {value:11,label:'November'},
      {value:12,label:'Desember'},
    ]
  }
};
export default Config;