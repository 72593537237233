<template>
  <fragment>
    <router-view></router-view>
    <nprogress-container></nprogress-container>
    <ImageFilter />
  </fragment>
</template>

<script>
import Vue from 'vue'
import GlobalVue from '@libs/Global.vue'
import Gen from '@libs/Gen'

import $ from 'jquery'
import _ from 'lodash'
import NprogressContainer from 'vue-nprogress/src/NprogressContainer'
import Fragment from 'vue-fragment'

global._ = _
global.jQuery = $
global.$ = $

Vue.use(Fragment.Plugin)

export default{
  extends:GlobalVue,
  components:{NprogressContainer},
  created(){
    /*
      ----------------------------
        JS/CSS GLOBAL
      ----------------------------
    */
    Gen.loadScriptCSS("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap");
    Gen.loadScriptCSS("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap");

    Gen.loadScriptJS("/dist/js/custom.js?v=1.4");

    this.refreshVariable()

    document.addEventListener('keypress', function (e) {
      if(e.target.classList.contains('allow-enter') == false && e.target.tagName!="TEXTAREA"){
        if (e.keyCode === 13 || e.which === 13) {
          e.preventDefault();
          return false;
        }
      }
    });
  },
  mounted(){
    $('.table-responsive').on('show.bs.dropdown', function () {
        $('.table-responsive').css( "overflow", "inherit" );
    });

    $('.table-responsive').on('hide.bs.dropdown', function () {
        $('.table-responsive').css( "overflow", "auto" );
    })

    $(document).on('click', '.table-responsive .v-select', ()=>{
      if($('.table-responsive').length){
        $('.table-responsive').css( "overflow", "inherit" );
      }
    })

    $(document).click((e)=> {
      if(!($(e.target).hasClass(".v-select") || ($(e.target).attr('class')||"").indexOf('vs__')>-1 || $(e.target).hasClass(".btn")) && $('.table-responsive').length){
        $('.table-responsive').css( "overflow", "auto" );
      }
    });
  },
  methods:{
    refreshVariable(){
      var variable = Gen.getStorage("bo_variable", {});
      _.forEach(variable, (v,k)=>{ this.$root[k] = v })
      Gen.apiRest("/variable", {}).then((res)=>{
        Gen.putStorage("bo_variable", res.data)
        _.forEach(res.data, (v,k)=>{ this.$set(this.$root, k, v) })
      })
    },
  }
}
</script>