import Vue from 'vue'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'

// Set the components prop default to return our fresh components
vSelect.props.components.default = () => ({
  OpenIndicator: {
    render: h => h('i', { class: 'fas fa-angle-down' })
  },
  Deselect: {
    render: h => h('i', { class: 'fas fa-times' })
  }
})

Vue.component('VSelect', vSelect)
